




























import Vue from 'vue';
import Component from 'vue-class-component';
import SubmissionActions from '@/components/submissions/SubmissionActions.vue';
import SubmissionDetail from '@/components/submissions/SubmissionDetail.vue';
import SubmissionData from '@/components/submissions/SubmissionData.vue';
import SurveyInfo from '@/components/surveys/SurveyInfo.vue';
import PageTitle from '@/components/ui/PageTitle.vue';
import Page from '@/components/ui/Page.vue';
import {submissionModule, surveyModule} from '@/store/modules';
import {SubmissionResource, SubmissionResponseResource} from '@/interfaces';
import {submissionResponseModule} from '@/store/modules/submissionResponseModule';
import {Route} from '@/enums';
import Spinner from '@/components/ui/Spinner.vue';

@Component({
  components: {
    Spinner,
    Page,
    PageTitle,
    SubmissionDetail,
    SurveyInfo,
    SubmissionActions,
    SubmissionData,
  },
})
export default class Submission extends Vue {
  private route = Route;

  get submissionId(): string {
    return this.$route.params.id;
  }

  get submission(): SubmissionResource {
    return submissionModule.find(this.$route.params.id);
  }

  get surveyId(): string | null {
    const surveyId = this.submission?.attributes?.surveyId;
    if (surveyId === undefined) {
      return null;
    }
    return String(surveyId);
  }

  // get survey(): SurveyResource | null {
  //   if (!this.surveyId) {
  //     return null;
  //   }
  //   return surveyModule.find(this.surveyId);
  // }

  private get submissionResponses(): SubmissionResponseResource[] {
    return submissionResponseModule.list;
  }

  private get submissionResponsesLoading(): boolean {
    return submissionResponseModule.loading;
  }

  async created() {
    submissionModule.reset();
    submissionResponseModule.reset();
    await submissionModule.loadById(this.submissionId);
    await submissionResponseModule.loadMulti({
      filters: {submissionId: this.submissionId},
    });
    if (this.surveyId) {
      await surveyModule.loadById(this.surveyId);
    }
  }
}
