import {Module} from 'vuex-class-modules';
import store from '@/store';
import {submissionResponseApi as api} from '@/api';
import {
  SubmissionResponseAttributes as Attributes,
  SubmissionResponseCreate as Create,
  SubmissionResponseResource as Resource,
} from '@/interfaces';
import {ResourceType} from '@/enums';
import {CrudModuleAbstract} from './CrudModuleAbstract';
import {parseISO} from 'date-fns';

const defaultItem = (): Resource => {
  return {
    id: 'new',
    type: ResourceType.SubmissionResponse,
    attributes: {
      id: 0,
      fieldId: 0,
      fieldName: '',
      fieldValue: '',
      submissionId: 0,
      surveyId: 0,
      surveyField: {id: 0, label: '', type: ''},
      items: [],
      created: new Date(),
      modified: new Date(),
    },
  };
};

@Module
class SubmissionResponseModule extends CrudModuleAbstract<
  Resource,
  Attributes,
  Create
> {
  public cast(item: Resource): Resource {
    item.attributes.created = parseISO(`${String(item.attributes.created)}`);
    item.attributes.modified = parseISO(`${String(item.attributes.modified)}`);
    return item;
  }
}

export const submissionResponseModule = new SubmissionResponseModule(
  {
    store,
    name: ResourceType.SubmissionResponse,
  },
  () => api,
  defaultItem
);
